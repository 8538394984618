/* 05.04 Header Logo CSS */

.header-logo {
    & a {
        position: relative;

        max-width: 158px;

        & img {
            width: 100%;
            padding: 15px 0;

            &.light-logo {
                position: absolute;
                top: 0;
                left: 0;

                opacity: 0;
            }

            &.dark-logo {
                width: 280px !important;
            }
        }
    }

    @media #{$extra-small-mobile} {
        & a {
            max-width: 120px;
        }
    }
}

.header-light {
    & .header-logo {
        & a {
            & img {
                &.light-logo {
                    opacity: 1;
                }

                &.dark-logo {
                    opacity: 0;
                }
            }
        }
    }
}