@import url('../css/vendor/muli-font.css');
@import url('../css/vendor/font-awesome-pro.min.css');
@import url('../css/vendor/pe-icon-7-stroke.css');
@import url('../css/plugins/animate.css');
@import "bootstrap";
@import "variabls";
@import "common";
@import "spacing";
@import "elements";
@import "titles";
@import "plugins";
@import "header";
@import "intro";
@import "testimonial";
@import "pages";
@import "blog";
@import "widget";
@import "footer";